<template>

  <div class="container">
    <div :class="{'radb': true, 'wrapped': props.wrapped}">
  
      <div 
        v-for="(item, item_key) in list"
        :key="item_key"
        :class="'ritem' + (sel == item_key ? ' checked ' : '')"
        @click="choose(item_key)"
        >
        <img loading="lazy" class="img" :src="item.img" :alt="item ? $t(item.tr) : ''">
        <div class="name">
          {{ item.tr ? $t(item.tr) : '' }}
        </div>
        <div class="desc">
          {{ item.desc }}
        </div>
        <div class="choose yes">
          <i class="icon-check"></i>
        </div>
        <div class="choose no"></div>
      </div>

    </div>
  </div>
  
</template>


<script setup lang="ts">

interface ListItem {
  img: string;
  tr: string;
  desc: string;
}

const emits = defineEmits<{
  (e: 'choose', itemKey: number): void;
  (e: 'create'): void;
}>();

const props = defineProps<{
  list: Record<number, ListItem>;
  selected?: number;
  wrapped?: boolean;
}>();

const sel = ref<number | undefined>(props.selected);

const choose = (item_key: number) => {
  sel.value = item_key;
  emits('choose', item_key);
}

watch(() => props.selected, (newVal) => {
  if (newVal !== undefined) {
    sel.value = newVal;
  }
});


</script>
 


<style scoped>
   
.container{
  container: radb / inline-size;
  width: 100%;
}

.radb{
  display: flex;
  gap: 0.5rem;
}
.ritem {
  padding: 10px 13px;
  background-color:  var(--un-background-color-gray);;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border: 3px transparent solid;
  position: relative;
}

.ritem:hover {
  background-color:  var(--un-background-color-gray-dark);
}

.ritem.checked {
  border: 3px var(--un-primary-back-color) solid;
  /* color: white; */
}
.name{
  font-weight: bold;
}

.img{
  width: 100%;
  max-width: 70px;
}

.desc{
  text-align: center;
  color: var(--un-text-color-gray);
  margin-bottom: auto;
}

/* wrapped */

.wrapped{
  flex-wrap: wrap;
}
.wrapped .ritem{
  width: 100%;
  max-width: 200px;
}

/*  */

.choose{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 20px;
  color: white;
}
.choose.yes{
  display: none;
  background-color: var(--un-primary-back-color);
  font-size: 0.6rem;
}
.choose.no{
  background-color: var(--un-background-color);
}
.checked .choose.yes{
  display: flex;
}
.checked .choose.no{
  display: none;
} 

@container radb (max-width: 600px) {
  .radb{
    flex-direction: column;
  }

  .ritem{    
    display: grid;
    gap: 0 10px;
    grid-template-areas: 
      "img name"
      "img desc";
  }

  .wrapped .ritem{
    max-width: 100%;
  }

  .name{
    grid-area: name;
    text-align: left;
  }

  .img{
    grid-area: img;
    width: 65px;
    height: 40px;
    aspect-ratio: none;
    object-fit: contain;
  }
  .desc{
    grid-area: desc;
    text-align: left;
  }
}
</style>